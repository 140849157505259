import { createRoot } from 'react-dom/client'
import { Suspense } from 'react'
import { Stats } from '@react-three/drei'
import { Leva } from 'leva'
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css'
import App from './App'

import { EarthoOneProvider } from '@eartho/one-client-react';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <EarthoOneProvider clientId='wBiT9paiHXFrFQvAvgNX' enabledProviders={['facebook', 'google', 'apple', 'github']} >
    <App />
  </EarthoOneProvider>
);

serviceWorkerRegistration.register();