import { Canvas, useFrame } from '@react-three/fiber'
import { Environment, Lightformer, ContactShadows, OrbitControls, Html } from '@react-three/drei'
import { Effects } from './Effects'
import { Vector3 } from 'three'
import { useEffect, useRef, useState } from 'react'

export default function App() {
  const orbitRef = useRef();


  const [cameraLookAtTarget, setCameraLookAtTarget] = useState([0, 5, 0]);
  const [cameraPosition, setCameraPosition] = useState([0, 100, 0.1]);

  const [activePage, setActivePage] = useState(null);


  function CameraControls({ to, target, lerping, controls }) {

    useFrame(({ camera }, delta) => {
      if (lerping) {
        camera.position.lerp(to, delta)
        controls.current.target.lerp(target, delta)
      }
    })
  }


  function Tile({ position, rotation, scale, children }) {
    return <Html className="content" position={position} rotation={rotation} scale={scale} transform occlude>
      {children}
    </Html>
  }


  return (<div style={{ width: '100%', height: '100%', position: 'relative' }}>
    <Canvas gl={{ logarithmicDepthBuffer: true, antialias: false }} dpr={[1, 1.5]} camera={{ position: cameraPosition, fov: 60 }}>

      {activePage}
      <Tile position={[0, 30, 0]} rotation={[(-Math.PI / 2), 0, 0]} scale={[1, 1, 1]}>
        <h1 style={{ fontSize: '1500%', whiteSpace: 'nowrap', textAlign: 'center' }} >CYBERWARE</h1>
        <h2 style={{ fontSize: '1000%', whiteSpace: 'nowrap', letterSpacing: 100, textAlign: 'center', paddingLeft: 100 }} >STUDIOS</h2>
        <a style={{ fontSize: '500%', whiteSpace: 'nowrap', textAlign: 'center', color: "white", marginBottom: -200, marginTop: 100, display: 'block', fontWeight: '900', textShadow: '0 0 60px cyan', cursor: 'pointer' }} onClick={() => {
          setCameraPosition([0, 10, 40]);
        }} >CONTACT</a>
      </Tile>

      <Tile position={[0, 10, 15]} rotation={[0, 0, 0]} scale={[1, 1, 1]}>
        <div style={{ textAlign: 'center' }}>

          <h1>Based in California</h1>
          <h2>Founded in 2023</h2>
          <a style={{ color: "white", marginTop: 20, display: 'block', fontWeight: '900', textShadow: '0 0 60px cyan', cursor: 'pointer' }} href="mailto:william@cyberwarestudios.com" target="_blank">Send Email</a>
          <a style={{ color: "cyan", marginTop: 40, display: 'block', fontWeight: '900', cursor: 'pointer' }} onClick={() => {
            setCameraPosition([0, 100, 0.1]);
          }}>{'<'} back</a>
        </div>
      </Tile>

      <hemisphereLight intensity={0.5} />
      <ContactShadows resolution={1024} frames={1} position={[0, 0, 0]} scale={15} blur={0.5} opacity={1} far={20} />

      <mesh scale={30} position={[45, 0, -45]} rotation={[-Math.PI / 2, 0, 0]}>
        <ringGeometry args={[0.9, 1, 3, 1]} />
        <meshStandardMaterial color="#00ffff" roughness={0.75} />
      </mesh>

      <mesh scale={40} position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <ringGeometry args={[0.9, 1, 4, 1]} />
        <meshStandardMaterial color="#00ffff" roughness={0.75} />
      </mesh>

      <mesh scale={30} position={[-50, 0, 50]} rotation={[-Math.PI / 2, 0, 0]}>
        <ringGeometry args={[0.9, 1, 50, 1]} />
        <meshStandardMaterial color="#00ffff" roughness={0.75} />
      </mesh>

      {/* We're building a cube-mapped environment declaratively.
          Anything you put in here will be filmed (once) by a cubemap-camera
          and applied to the scenes environment, and optionally background. */}
      <Environment resolution={512}>
        {/* Ceiling */}
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />
        {/* Sides */}
        <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
        <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />
        {/* Key */}
        <Lightformer form="ring" color="white" intensity={100} scale={2} position={[10, 5, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
      </Environment>
      <Effects />
      <OrbitControls ref={orbitRef} enablePan={false} enableZoom={false} target={cameraLookAtTarget} />
      <CameraControls to={new Vector3(...cameraPosition)} target={new Vector3(...cameraLookAtTarget)} lerping={true} controls={orbitRef} />
    </Canvas>
  </div>
  )
}
